<template>
  <div>
    <div id="section-1" class="section section-1">
      <div class="content">
        <div class="text">
          <div class="text-1 color-white">安卓旗舰</div>
          <div class="text-2 color-white">全新店教授安卓收银系统，耀世登场</div>
          <div>
            <a
              class="experience color-white"
              >立即下载</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="section section-2">
      <div class="content">
        <div class="text">
          <div class="text-1 color-white">商用旗舰，店教授定义</div>
          <div class="text-2 color-white">
            无缝兼容19种不同厂家的设备、高端前沿的设计、千锤百炼的商业级稳定性，
            <br />
            以及快速上手的易用性。你的门店，从此高大上！
          </div>

          <div class="text-3 color-white">
            不同厂家设备
            <br />
            全兼容
          </div>
          <div class="text-4 color-white">
            简洁的UI
            <br />
            和体验设计
          </div>
          <div class="text-5 color-white">商业级稳定性</div>
          <div class="text-6 color-white">快速上手</div>
        </div>
      </div>
    </div>

    <div class="section section-3">
      <div class="content">
        <div class="text">
          <div class="section-text-2 color-black mr30">收银，从此成为一种享受</div>
          <div class="section-text-3 color-black">
            全新店教授安卓收银解决方案的出现，让你彻底告别又
            <br />
            黑又笨的收银机，你可以在任意你喜欢的设备上运行
            <br />
            店教授收银系统。我们为你甄选了业内优秀的安卓硬
            <br />
            件组合，优秀的软件加上优秀的工业设计，让你彻底
            <br />
            告别传统收银机，你的前台从未如此优雅。
          </div>
        </div>
      </div>
    </div>

    <div class="section section-4">
      <div class="content">
        <div class="text">
          <div class="section-text-2 color-black mr30">商业级兼容性</div>
          <div class="section-text-3 color-black">
            当然您亦无需担心兼容问题，开店所需的小票机、扫码枪、钱箱、打印机、
            <br />
            监控等设备全都已经为您兼容妥当。
            <br />
            蓝牙，LAN, USB接口，WIFI，移动数据网络，串口，满足所有业态的需求。
          </div>
        </div>
      </div>
    </div>

    <div class="section section-5">
      <div class="content">
        <div class="text">
          <div class="section-text-1 color-white">全新安卓收银系统</div>
          <div class="section-text-2 color-white mr30">5年磨一剑的全新系统</div>
          <div class="section-text-3 color-white">
            你从未见过如此优雅的收银系统！他配得上任何高端的场合，甚至让你沉迷其中。
            <br />
            5年匠心沉淀，通过大数据优化的操作体验，无需培训快速上手。
          </div>
        </div>
      </div>
    </div>

    <div class="section section-6">
      <div class="content">
        <div class="text">
          <div class="section-text-1 color-white">手机支付</div>
          <div class="section-text-2 color-white mr30">深度集成多种支付方式</div>
          <div class="section-text-3 color-white">
            店教授收银系统同时支持银联、支付宝、微信、京东钱包
            <br />
            和团购验证，让你一步到位，从此告别排队结账，顾客
            <br />
            当然更爱来。
          </div>
        </div>
      </div>
    </div>

    <div class="section section-7">
      <div class="content">
        <div class="text">
          <div class="section-text-1 color-black">外卖订单</div>
          <div class="section-text-2 color-black mr30">无缝对接3大外卖平台</div>
          <div class="section-text-3 color-black">
            深度集成百度外卖、饿了么、美团外卖 ，用收银系统就
            <br />
            能处理外卖订单，订单直接到后厨，再也不用抱着手机
            <br />
            前台后厨两边跑。
          </div>
        </div>
      </div>
    </div>

    <div class="section section-8">
      <div class="content">
        <div class="text">
          <div class="section-text-1 color-white">系统后台</div>
          <div class="section-text-2 color-white mr30">
            50+种报表，500强企业也能用
          </div>
          <div class="section-text-3 color-white">
            店教授云后台内置门店所需的多种营业报表，联手国际知名咨询公司A.S.Louken潜心研发，从
            <br />
            单店到连锁都能用，帮助您分析生意、进行连锁管理，并做出科学的决策。
          </div>

          <div class="text-4 color-white">销售／利润趋势分析</div>
          <div class="text-5 color-white">商品／人群占比统计</div>
          <div class="text-6 color-white">餐饮业口味销售统计</div>
          <div class="text-7 color-white">会员报表</div>
        </div>
      </div>
    </div>

    <div class="section section-9">
      <div class="content">
        <div class="text">
          <div class="section-text-1 color-white">CRM功能</div>
          <div class="section-text-2 color-white mr30">让访客都成为回头客</div>
          <div class="section-text-3 color-white">
            无需羡慕大企业的CRM ，现在您可以设置会员积分制度，并
            <br />
            根据不同的客户设置充值送、满减和返现 活动，实实在在回
            <br />
            流更多资金。
          </div>
        </div>
      </div>
    </div>

    <div class="section section-10">
      <div class="content">
        <div class="text">
          <div class="text-1 color-black">全方位的营销筛选条件</div>
          <div class="text-2 color-black">购买过奶粉，没购买过尿布的客户</div>
          <div class="text-3 color-black">
            上个月消费过，这个月还没消费的客户
          </div>
          <div class="text-4 color-black">下个月即将过生日的会员</div>
        </div>
      </div>
    </div>

    <div class="section section-11">
      <div class="content">
        <div class="text">
          <div class="text-1 color-black">应用场景</div>
          <div class="text-2 color-black">满足不同的业态</div>
        </div>
      </div>
    </div>

    <div class="section section-12">
      <div class="content">
        <div class="text">
          <div class="section-text-1 color-white">功能与版本</div>
          <div class="section-text-2 color-white mr30">
            200+功能，多种行业1键切换
          </div>
          <div class="section-text-3 color-white">
            支持零售、餐饮、服装、生活服务、母婴，无需单独下载软件，版本间一键切换
            <br />
            无需反复安装。
          </div>

          <div id="feature-open" class="feature-open color-orange">
            <div>查看所有功能</div>
          </div>
        </div>
      </div>
    </div>

    <div id="feature" class="section section-13 feature">
      <div class="content">
        <div class="feature-content">
          <div id="feature-skip" class="feature-skip color-orange">
            <div>跳过功能介绍</div>
          </div>

          <div class="feature-title color-orange">店教授收银系统前台</div>

          <div class="feature-subtitle color-black">通用收银功能</div>

          <div class="feature-cell color-gray">○ PC客户端点餐收银系统</div>
          <div class="feature-cell color-gray">○ iPad点餐收银系统</div>
          <div class="feature-cell color-gray">○ Android Pad点餐收银系统</div>
          <div class="feature-cell color-gray">○ iPhone点餐收银系统</div>

          <div class="feature-cell color-gray">○ Android手机点餐收银系统</div>
          <div class="feature-cell color-gray">○ 安卓平板自助点餐系统</div>
          <div class="feature-cell color-gray">○ 查看库存、盘点</div>
          <div class="feature-cell color-gray">○ 报损</div>

          <div class="feature-cell color-gray">○ 快捷打印标签</div>
          <div class="feature-cell color-gray">○ 商品添加、编辑</div>
          <div class="feature-cell color-gray">○ 订货、调货、退货</div>
          <div class="feature-cell color-gray">○ 锁屏、弹钱箱</div>

          <div class="feature-cell color-gray">○ 会员生日提醒</div>
          <div class="feature-cell color-gray">○ 广告播放</div>
          <div class="feature-cell color-gray">● 微信自助点餐系统</div>
          <div class="feature-cell color-gray">● 手机APP自助点餐系统</div>

          <div class="feature-cell color-gray">○ 快捷收银</div>
          <div class="feature-cell color-gray">○ 并桌收银</div>
          <div class="feature-cell color-gray">○ 换桌</div>
          <div class="feature-cell color-gray">○ 组合支付</div>

          <div class="feature-cell color-gray">○ 无码收银</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">餐饮版收银系统</div>

          <div class="feature-cell color-gray">○ 挂单取单</div>
          <div class="feature-cell color-gray">○ 开台</div>
          <div class="feature-cell color-gray">○ 划菜</div>
          <div class="feature-cell color-gray">○ 催菜</div>

          <div class="feature-cell color-gray">○ 上菜计时</div>
          <div class="feature-cell color-gray">○ 估清</div>
          <div class="feature-cell color-gray">○ 交接班</div>
          <div class="feature-cell color-gray">● 服务评价管理</div>

          <div class="feature-cell color-gray">● 菜品点评</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">零售版收银系统</div>

          <div class="feature-cell color-gray">○ 商品扫码收银</div>
          <div class="feature-cell color-gray">○ 批发与零售模式切换</div>
          <div class="feature-cell color-gray">○ 商品点评</div>
          <div class="feature-cell color-gray">○ 电子秤（条码称、通讯称）</div>

          <div class="feature-cell color-gray">● 会员寄存及取件</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-title color-orange">店教授云后台</div>

          <div class="feature-subtitle color-black">商品管理系统</div>

          <div class="feature-cell color-gray">○ 商品分类管理</div>
          <div class="feature-cell color-gray">○ 商品基础信息管理</div>
          <div class="feature-cell color-gray">○ 商品价格管理</div>
          <div class="feature-cell color-gray">○ 商品积分管理</div>

          <div class="feature-cell color-gray">○ 无码商品管理</div>
          <div class="feature-cell color-gray">○ 口味管理</div>
          <div class="feature-cell color-gray">○ 组装拆分管理</div>
          <div class="feature-cell color-gray">○ 库存管理</div>

          <div class="feature-cell color-gray">○ 盘点管理</div>
          <div class="feature-cell color-gray">○ 货单管理</div>
          <div class="feature-cell color-gray">○ 过期管理</div>
          <div class="feature-cell color-gray">○ 报损管理</div>

          <div class="feature-cell color-gray">○ 原材料管理</div>
          <div class="feature-cell color-gray">○ 商品销售统计报表</div>
          <div class="feature-cell color-gray">○ 商品销售热力图</div>
          <div class="feature-cell color-gray">○ 时价管理</div>

          <div class="feature-cell color-gray">○ 口味管理</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">原材料管理系统</div>

          <div class="feature-cell color-gray">○ 原材料商品管理</div>
          <div class="feature-cell color-gray">○ 原材料配方管理</div>
          <div class="feature-cell color-gray">○ 半成品管理</div>
          <div class="feature-cell color-gray">○ 配方查询</div>

          <div class="feature-cell color-gray">○ 原材料消耗报表</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">会员管理系统</div>

          <div class="feature-cell color-gray">○ 会员等级管理</div>
          <div class="feature-cell color-gray">○ 会员信息管理</div>
          <div class="feature-cell color-gray">○ 会员报表管理</div>
          <div class="feature-cell color-gray">○ 会员卡</div>

          <div class="feature-cell color-gray">○ 退换卡管理</div>
          <div class="feature-cell color-gray">○ 赊账管理</div>
          <div class="feature-cell color-gray">○ 次卡管理</div>
          <div class="feature-cell color-gray">○ 月卡、季卡、年卡管理</div>

          <div class="feature-cell color-gray">○ 购物卡管理</div>
          <div class="feature-cell color-gray">○ 会员生日管理</div>
          <div class="feature-cell color-gray">○ 会员积分管理</div>
          <div class="feature-cell color-gray">○ 会员充值管理</div>

          <div class="feature-cell color-gray">○ 会员精准营销管理</div>
          <div class="feature-cell color-gray">○ 潜水会员管理</div>
          <div class="feature-cell color-gray">○ 会员积分设置</div>
          <div class="feature-cell color-gray">○ 会员消费历史查询</div>

          <div class="feature-cell color-gray">○ 会员购买商品查询</div>
          <div class="feature-cell color-gray">○ 会员标签</div>
          <div class="feature-cell color-gray">● 微信会员管理</div>
          <div class="feature-cell color-gray">● 微信推送服务</div>

          <div class="feature-cell color-gray">○ 会员赊账</div>
          <div class="feature-cell color-gray">○ 短信服务</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">营销管理系统</div>

          <div class="feature-cell color-gray">○ 打折促销管理</div>
          <div class="feature-cell color-gray">○ 套餐营销管理</div>
          <div class="feature-cell color-gray">○ 返现营销管理</div>
          <div class="feature-cell color-gray">○ 搭赠换购营销管理</div>

          <div class="feature-cell color-gray">○ 充值赠送管理</div>
          <div class="feature-cell color-gray">○ 优惠券管理</div>
          <div class="feature-cell color-gray">○ 营销时间段管理</div>
          <div class="feature-cell color-gray">○ 营销对象管理</div>

          <div class="feature-cell color-gray">○ 营销门店设置</div>
          <div class="feature-cell color-gray">○ 营销效果反馈表</div>
          <div class="feature-cell color-gray">○ 折扣统计报表</div>
          <div class="feature-cell color-gray">● 短信服务</div>

          <div class="feature-cell color-gray">○ 微信优惠推送</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">积分管理系统</div>

          <div class="feature-cell color-gray">○ 积分规则设置</div>
          <div class="feature-cell color-gray">○ 积分查询管理</div>
          <div class="feature-cell color-gray">○ 积分兑换管理</div>
          <div class="feature-cell color-gray">○ 积分抵现管理</div>

          <div class="feature-cell color-gray">○ 积分兑换统计报表</div>
          <div class="feature-cell color-gray">○ 积分变动提醒服务</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">供应商管理</div>

          <div class="feature-cell color-gray">● 供应商信息管理</div>
          <div class="feature-cell color-gray">● 供应商结算</div>
          <div class="feature-cell color-gray">● 结算记录查询</div>
          <div class="feature-cell color-gray">● 商品供应商批量设置</div>

          <div class="feature-cell color-gray">● 供应商拆单管理</div>
          <div class="feature-cell color-gray">● 门店供货销售状况查询</div>
          <div class="feature-cell color-gray">● 供应商品报表查询</div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">进销存管理系统</div>

          <div class="feature-cell color-gray">○ 订货管理</div>
          <div class="feature-cell color-gray">○ 进货管理</div>
          <div class="feature-cell color-gray">○ 调货管理</div>
          <div class="feature-cell color-gray">○ 退货管理</div>

          <div class="feature-cell color-gray">○ 货流查询汇总</div>
          <div class="feature-cell color-gray">○ 货款管理</div>
          <div class="feature-cell color-gray">○ 推荐订货商品设置</div>
          <div class="feature-cell color-gray">○ 库存查询与管理</div>

          <div class="feature-cell color-gray">○ 订货通知服务</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">称重管理</div>

          <div class="feature-cell color-gray">○ 称重商品设置</div>
          <div class="feature-cell color-gray">○ 条码秤设置</div>
          <div class="feature-cell color-gray">○ 通讯秤设置</div>
          <div class="feature-cell color-gray">○ 收银一体称设置</div>

          <div class="feature-subtitle color-black">支付管理</div>

          <div class="feature-cell color-gray">○ 现金支付管理</div>
          <div class="feature-cell color-gray">○ 会员卡支付管理</div>
          <div class="feature-cell color-gray">○ 银联卡支付管理</div>
          <div class="feature-cell color-gray">○ 微信支付管理</div>

          <div class="feature-cell color-gray">○ 支付宝支付管理</div>
          <div class="feature-cell color-gray">○ 京东支付管理</div>
          <div class="feature-cell color-gray">○ 苹果支付管理</div>
          <div class="feature-cell color-gray">○ 组合支付管理</div>

          <div class="feature-cell color-gray">○ 第三方支付管理</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">线上订单系统</div>

          <div class="feature-cell color-gray">● 线上销售商品设置</div>
          <div class="feature-cell color-gray">● 在线签到奖励设置</div>
          <div class="feature-cell color-gray">● 微信订单管理</div>
          <div class="feature-cell color-gray">● 自助单管理</div>

          <div class="feature-cell color-gray">● 预约单管理</div>
          <div class="feature-cell color-gray">● 线上订单查询统计</div>
          <div class="feature-cell color-gray">○ 主流团购、外卖对接设置</div>
          <div class="feature-cell color-gray">○ 线上支付方式设置</div>

          <div class="feature-cell color-gray">○ 线上充值规则设置</div>
          <div class="feature-cell color-gray">○ 订单配送管理</div>
          <div class="feature-cell color-gray">○ 手机APP订单管理</div>
          <div class="feature-cell color-gray">○ 门店预约</div>

          <div class="feature-subtitle color-black">连锁管理</div>

          <div class="feature-cell color-gray">● 营业汇总</div>
          <div class="feature-cell color-gray">● 门店捆绑设置</div>
          <div class="feature-cell color-gray">● 门店权限设置</div>
          <div class="feature-cell color-gray">● 分店营业报表</div>

          <div class="feature-cell color-gray">● 营销门店设置</div>
          <div class="feature-cell color-gray">● 商品共享设置</div>
          <div class="feature-cell color-gray">● 会员共享设置</div>
          <div class="feature-cell color-gray">● 储值共享管理</div>

          <div class="feature-cell color-gray">● 总仓库管理</div>
          <div class="feature-cell color-gray">● 配货服务</div>
          <div class="feature-cell color-gray">● 门店结算管理</div>
          <div class="feature-cell color-gray">● 跨店库存查询服务</div>

          <div class="feature-cell color-gray">● 通知服务</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">财务报表</div>

          <div class="feature-cell color-gray">○ 营业报表</div>
          <div class="feature-cell color-gray">○ 商品报损报表</div>
          <div class="feature-cell color-gray">○ 会员消费报表</div>
          <div class="feature-cell color-gray">○ 商品销售报表</div>

          <div class="feature-cell color-gray">○ 退货报表</div>
          <div class="feature-cell color-gray">○ 日结报表</div>
          <div class="feature-cell color-gray">○ 门店营业趋势分析</div>
          <div class="feature-cell color-gray">○ 分类统计图表</div>

          <div class="feature-cell color-gray">○ 导购销售报表</div>
          <div class="feature-cell color-gray">○ 营业额支付方式统计表</div>
          <div class="feature-cell color-gray">○ 商品销售热力图</div>
          <div class="feature-cell color-gray">○ 商品销售统计表</div>

          <div class="feature-cell color-gray">○ 商品分类消费统计</div>
          <div class="feature-cell color-gray">○ 商品消费时段分析报表</div>
          <div class="feature-cell color-gray">○ 商品折扣报表</div>
          <div class="feature-cell color-gray">○ 会员充值明细表</div>

          <div class="feature-cell color-gray">○ 会员储值汇总表</div>
          <div class="feature-cell color-gray">○ 会员余额对账表</div>
          <div class="feature-cell color-gray">○ 次卡消费报表</div>
          <div class="feature-cell color-gray">○ 购物卡消费报表</div>

          <div class="feature-cell color-gray">○ 积分使用核对表</div>
          <div class="feature-cell color-gray">○ 会员消费历史对账表</div>
          <div class="feature-cell color-gray">○ 赊账报表</div>
          <div class="feature-cell color-gray">○ 会员消费频次表</div>

          <div class="feature-cell color-gray">○ 会员消费占比图</div>
          <div class="feature-cell color-gray">○ 交接班记录</div>
          <div class="feature-cell color-gray">○ 现金收支记录</div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">员工管理</div>

          <div class="feature-cell color-gray">○ 员工基础信息管理</div>
          <div class="feature-cell color-gray">○ 员工权限管理</div>
          <div class="feature-cell color-gray">○ 导购员基础信息管理</div>
          <div class="feature-cell color-gray">○ 提成设置</div>

          <div class="feature-cell color-gray">○ 业绩查询</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">团购、外卖对接</div>

          <div class="feature-cell color-gray">○ 美团验证设置</div>
          <div class="feature-cell color-gray">○ 大众点评验证设置</div>
          <div class="feature-cell color-gray">○ 百度糯米验证设置</div>
          <div class="feature-cell color-gray">○ 百度外卖订单对接设置</div>

          <div class="feature-cell color-gray">○ 美团外卖订单对接设置</div>
          <div class="feature-cell color-gray">○ 饿了么订单对接设置</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">系统设置</div>

          <div class="feature-cell color-gray">○ 会员支付密码开关设置</div>
          <div class="feature-cell color-gray">○ 会员固定金额设置</div>
          <div class="feature-cell color-gray">○ 0库存销售管理</div>
          <div class="feature-cell color-gray">○ 库存编辑权限设置</div>

          <div class="feature-cell color-gray">○ 收银零头处理</div>
          <div class="feature-cell color-gray">○ 消费税服务费设置</div>
          <div class="feature-cell color-gray">● 后台安全登录设置</div>
          <div class="feature-cell color-gray">○ 通知提醒设置</div>

          <div class="feature-cell color-gray">● 企业logo设置</div>
          <div class="feature-cell color-gray">○ 收银小票模板设置</div>
          <div class="feature-cell color-gray">○ 厨打小票模板设置</div>
          <div class="feature-cell color-gray">○ 系统操作日志</div>

          <div class="feature-cell color-gray">○ 门店通知</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-title color-orange">我的店铺APP</div>

          <div class="feature-subtitle color-black">移动办公系统</div>

          <div class="feature-cell color-gray">○ 营业情况实时推送服务</div>
          <div class="feature-cell color-gray">○ 营业概况查询</div>
          <div class="feature-cell color-gray">○ 销售分类报表查询</div>
          <div class="feature-cell color-gray">○ 手机订货</div>

          <div class="feature-cell color-gray">○ 手机进货</div>
          <div class="feature-cell color-gray">○ 店铺监控手机端在线观看</div>
          <div class="feature-cell color-gray">○ 手机远程控制权限</div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-title color-orange">微信店铺</div>

          <div class="feature-subtitle color-black">微信店铺平台</div>

          <div class="feature-cell color-gray">● 微信外卖平台</div>
          <div class="feature-cell color-gray">● 微信预约平台</div>
          <div class="feature-cell color-gray">● 微信自助服务</div>
          <div class="feature-cell color-gray">● 微信会员打通</div>

          <div class="feature-cell color-gray">● 会员在线充值</div>
          <div class="feature-cell color-gray">● 会员在线查询</div>
          <div class="feature-cell color-gray">● 在线结算设置</div>
          <div class="feature-cell color-gray">● 消费历史查询</div>

          <div class="feature-cell color-gray">● 微信通知服务</div>
          <div class="feature-cell color-gray">● 微信营销</div>
          <div class="feature-cell color-gray">● 微信签到</div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-title color-orange">KDS智能厨打APP</div>

          <div class="feature-subtitle color-black">智能厨打</div>

          <div class="feature-cell color-gray">◆ 多厨打分菜管理系统</div>
          <div class="feature-cell color-gray">◆ 追菜优先排序管理</div>
          <div class="feature-cell color-gray">◆ ADS配菜管理系统</div>
          <div class="feature-cell color-gray">◆ KDS智能平板厨打管理</div>

          <div class="feature-cell color-gray">◆ 取餐呼叫管理</div>
          <div class="feature-cell color-gray">◆ 烹饪时间统计</div>
          <div class="feature-cell color-gray">◆ 烹饪时间预警</div>
          <div class="feature-cell color-gray">◆ TV广告服务</div>

          <div class="feature-title color-orange">技术支持</div>

          <div class="feature-subtitle color-black">服务</div>

          <div class="feature-cell color-gray">● 专属1对1技术支持</div>
          <div class="feature-cell color-gray">● 系统部署、迁移服务</div>
          <div class="feature-cell color-gray">● 开放API</div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-title color-orange">移动CRM</div>

          <div class="feature-subtitle color-black">移动CRM</div>

          <div class="feature-cell color-gray">● 查看门店销售员排名及贡献</div>
          <div class="feature-cell color-gray">● 会员精准营销管理</div>
          <div class="feature-cell color-gray">● 销售员社交分享</div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-footnote color-orange">
            ○为专业版功能，●为在专业版基础上企业版特别功能，◆为KDS APP特别功能。
          </div>

          <div id="feature-close" class="feature-close color-orange">
            <div>关闭功能介绍</div>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-14">
      <div class="content">
        <div class="text">
          <div class="text-1 color-orange">企业级服务，你再无后顾之忧</div>
          <div class="section-text-3 color-gray">
            店教授提供专业的咨询顾问，200+人的呼叫中心支持7*12小时的电话响应，
            <br />
            遍布全国多个城市的顾问团队随时待命为您提供1对1顾问支持、
            <br />
            技术支持、部署和迁移服务。
          </div>
        </div>
      </div>
    </div>

    <!-- <link rel="stylesheet" href="/main/resource/css/common/case.css" /> -->

    <div class="section case-section-1">
      <div class="content">
        <div class="text">
          <div class="text-1 color-black">12</div>
          <div class="text-2 color-black">10000</div>
          <div class="text-3 color-black">7×12</div>
        </div>
      </div>
    </div>

    <div class="section case-section-2">
      <div class="content">
        <div class="text">
          <div class="text-1 color-black">10000企业都在用</div>
          <div class="text-2 color-black">
            <a href="">查看所有案例 ></a>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-16">
      <div class="content">
        <div class="text">
          <div class="text-1 color-orange">
            超越自我
            <br />
            让门店高大上，让生意更简单
          </div>

          <div class="text-2 color-gray">店教授安卓平板收银方案</div>
          <div class="text-3 color-gray">
            商米T1安卓收银机 | 店教授收银软件专业一年版
          </div>
          <div class="text-4 color-black">
            <span style="font-size: 28px">￥</span>
            <span style="font-size: 38px"> 2999</span>
            <span style="font-size: 28px">元/套</span>
          </div>
          <div>
            <a
              class="purchase"
              >立即购买</a
            >
            <a
              class="download"
              >立即下载</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../../../assets/js/jquery/jquery.js'
import '../../../assets/js/common/common.js'
import "../../../assets/css/cloud/common.css";
import "../../../assets/css/common/common.css";
import "../../../assets/css/common/case.css";
export default {};
</script>

<style scoped>
.section-1 {
  height: 735px;
  background-image: url("../../../assets/images/product/android/section_1.jpg");
}
.section-1 .content .text {
  position: absolute;
  width: 600px;
  text-align: left;
  top: 260px;
  left: 50px;
}
.section-1 .content .text .text-1 {
  font-size: 62px;
}
.section-1 .content .text .text-2 {
  font-size: 30px;
  margin: 50px 0 0 0;
  letter-spacing: 2px;
}
.section-1 .content .text .experience {
  position: absolute;
  display: block;
  font-size: 20px;
  width: 245px;
  height: 68px;
  line-height: 68px;
  text-align: center;
  margin: 40px auto 0 auto;
  background-color: #ff6727;
  border-radius: 2px;
}
.section-2 {
  height: 697px;
  background-image: url("../../../assets/images/product/android/section_2.jpg");
}
.section-2 .content .text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0;
}
.section-2 .content .text .text-1 {
  position: absolute;
  font-size: 40px;
  width: 100%;
  top: 130px;
}
.section-2 .content .text .text-2 {
  position: absolute;
  font-size: 20px;
  width: 100%;
  top: 220px;
  letter-spacing: 1px;
  line-height: 36px;
}
.section-2 .content .text .text-3,
.section-2 .content .text .text-4,
.section-2 .content .text .text-5,
.section-2 .content .text .text-6 {
  position: absolute;
  font-size: 16px;
  width: 600px;
  top: 480px;
  line-height: 26px;
}
.section-2 .content .text .text-3 {
  left: 50px;
}
.section-2 .content .text .text-4 {
  left: 230px;
}
.section-2 .content .text .text-5 {
  left: 400px;
}
.section-2 .content .text .text-6 {
  left: 550px;
}
.section-3 {
  height: 828px;
  background-image: url("../../../assets/images/product/android/section_3.jpg");
}
.section-3 .content .text {
  position: absolute;
  width: 600px;
  top: 280px;
  left: 50px;
}
.section-4 {
  height: 751px;
  background-image: url("../../../assets/images/product/android/section_4.jpg");
}
.section-4 .content .text {
  position: absolute;
  width: 100%;
  top: 110px;
  text-align: center;
}
.section-5 {
  height: 1000px;
  background-image: url("../../../assets/images/product/android/section_5.jpg");
}
.section-5 .content .text {
  position: absolute;
  width: 100%;
  top: 160px;
  text-align: center;
}
.section-6 {
  height: 790px;
  background-image: url("../../../assets/images/product/android/section_6.jpg");
}
.section-6 .content .text {
  position: absolute;
  width: 600px;
  top: 190px;
  left: 650px;
}
.section-7 {
  height: 558px;
  background-image: url("../../../assets/images/product/android/section_7.jpg");
}
.section-7 .content .text {
  position: absolute;
  width: 600px;
  top: 160px;
  left: 50px;
}
.section-8 {
  height: 981px;
  background-image: url("../../../assets/images/product/android/section_8.jpg");
}
.section-8 .content .text {
  position: absolute;
  width: 100%;
  top: 130px;
  text-align: center;
}
.section-8 .content .text .text-4,
.section-8 .content .text .text-5,
.section-8 .content .text .text-6,
.section-8 .content .text .text-7 {
  position: absolute;
  width: 300px;
  top: 400px;
  text-align: center;
}
.section-8 .content .text .text-4 {
  left: 85px;
}
.section-8 .content .text .text-5 {
  left: 335px;
}
.section-8 .content .text .text-6 {
  left: 585px;
}
.section-8 .content .text .text-7 {
  left: 835px;
}
.section-9 {
  height: 686px;
  background-image: url("../../../assets/images/product/android/section_9.jpg");
}
.section-9 .content .text {
  position: absolute;
  font-size: 40px;
  width: 600px;
  top: 210px;
  left: 50px;
}
.section-10 {
  height: 670px;
  background-image: url("../../../assets/images/product/android/section_10.jpg");
}
.section-10 .content .text {
  position: absolute;
  font-size: 40px;
  width: 100%;
  top: 0;
}
.section-10 .content .text .text-1 {
  position: absolute;
  font-size: 40px;
  width: 100%;
  top: 120px;
  text-align: center;
}
.section-10 .content .text .text-2,
.section-10 .content .text .text-3,
.section-10 .content .text .text-4 {
  position: absolute;
  font-size: 20px;
  width: 400px;
  top: 500px;
}
.section-10 .content .text .text-2 {
  left: 25px;
}
.section-10 .content .text .text-3 {
  left: 420px;
}
.section-10 .content .text .text-4 {
  left: 910px;
}
.section-11 {
  height: 1094px;
  background-image: url("../../../assets/images/product/android/section_11.jpg");
}
.section-11 .content .text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0;
}
.section-11 .content .text .text-1 {
  position: absolute;
  font-size: 22px;
  width: 100%;
  top: 105px;
}
.section-11 .content .text .text-2 {
  position: absolute;
  font-size: 40px;
  width: 100%;
  top: 150px;
}
.section-12 {
  height: 1199px;
  background-image: url("../../../assets/images/product/android/section_12.jpg");
}
.section-12 .content .text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 140px;
}
.section-12 .content .text .feature-open {
  position: absolute;
  top: 500px;
}
.section-13 {
  height: 0;
  background-color: #f4f4f6;
}
.section-14 {
  height: 599px;
  background-image: url("../../../assets/images/product/android/section_14.jpg");
}
.section-14 .content .text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 190px;
}
.section-14 .content .text .text-1 {
  font-size: 48px;
}
.section-14 .content .text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 190px;
}
.section-16 {
  height: 1349px;
  background-image: url("../../../assets/images/product/android/section_16.jpg");
}
.section-16 .content .text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 160px;
}
.section-16 .content .text .text-1 {
  font-size: 48px;
  line-height: 80px;
}
.section-16 .content .text .text-2 {
  font-size: 24px;
  margin: 640px 0 0 0;
  letter-spacing: 1px;
}
.section-16 .content .text .text-3 {
  font-size: 22px;
  margin: 20px 0 0 0;
  letter-spacing: 1px;
}
.section-16 .content .text .text-4 {
  font-size: 32px;
  margin: 40px 0 0 0;
}
.section-16 .content .text .purchase {
  position: absolute;
  display: block;
  font-size: 20px;
  width: 245px;
  height: 68px;
  line-height: 68px;
  text-align: center;
  margin: 40px 0 0 340px;
  border-radius: 2px;
  border: solid 1px #ff6727;
  color: #ff6727;
}
.section-16 .content .text .download {
  position: absolute;
  display: block;
  font-size: 20px;
  width: 245px;
  height: 68px;
  line-height: 68px;
  text-align: center;
  margin: 40px 0 0 630px;
  background-color: #ff6727;
  border-radius: 2px;
  color: #fff;
}
</style>